@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --color-blue: #007be0;
  --color-orange: #ff7f11;
  --color-gold: #eebb00;
  --color-pink: #e36374;
  --color-green: #180;
  --color-blue: #007be0;
  --primary-color: var(--color-green);
  --highlight-color: #33a3ff;
  --secondary-color: var(--color-gold);
  --bg-color: #f4f4f4;
  --side-bar-width: 200px;
  --btn-bg-color: #fff;
  --urgent-color: #d20;
  --disabled-color: #bbb;
}

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: var(--bg-color);
}

ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 1em;
  color: var(--heading-color);
  display: inline-block;
}

.btn {
  background: var(--btn-bg-color);
  padding: 8px 12px;
  height: 50%;
  margin: auto;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
}

.secondary {
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.inverted {
  background: var(--primary-color);
  color: var(--btn-bg-color);
  border: 1px solid var(--btn-bg-color);
}

.btn:hover {
  color: var(--btn-bg-color);
  background-color: var(--primary-color);
}

.secondary:hover {
  color: var(--btn-bg-color);
  background-color: var(--secondary-color);
}

.inverted:hover {
  background: var(--btn-bg-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.btn:disabled {
  color: #999;
  background-color: #ccc;
  border-color: #999;
  cursor: not-allowed;
}

.BasicList li {
  background: white;
  margin: 15px;
  padding: 10px;
  border-radius: 8px;
  max-width: 600px;
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}

label span {
  display: block;
  margin-bottom: 6px;
}

input,
textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  min-height: 160px;
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.warning {
  color: goldenrod;
  background: lightgoldenrodyellow;
  border: 1px solid goldenrod;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.center {
  margin: auto;
  /* flex-grow: 1; */
  width: 90%;
  max-width: 400px;
  text-align: center;
}
