.App {
  display: flex;
}
.App .container {
  flex-grow: 1;
  padding: 0;
}
.page-structure {
  display: flex;
  align-content: space-between;
}

input {
  max-width: 600px;
  margin: 8px;
}

.page-content {
  padding: 10px 20px;
}

.no-chat {
  max-width: 100%;
}

.chat-system {
  padding: 16px;
  margin-left: 8px;
  max-width: 40%;
  min-width: 300px;
  background: #dbdbdb;
  border-radius: 8px;
  height: min-content;
}
