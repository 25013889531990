.description {
  background: #fff;
  padding: 8px 20px;
  text-align: left;
  border-radius: 8px;
}

.inputs {
  display: flex;
}
