.top-bar {
  flex-grow: 1;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  background: #180;
}
.top-bar ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
}
.top-bar ul button {
  margin-right: 60px;
}
.top-bar .logo {
  font-weight: bold;
  color: var(--heading-color);
  letter-spacing: -2px;
  font-size: 2em;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}

.top-bar a {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
}
